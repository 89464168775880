import { AccessButton } from "@acdc2/ui/components/access-button";
import {
  AttributeDeleteButtonFragment,
  useDeleteAttributeMutation,
} from "../client/generated";
import {
  EditorAction,
  PaneIdentifier,
  TabIdentifier,
} from "../editor/reducers";
import { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { ButtonProps } from "@acdc2/ui/components/button";

type Props = ButtonProps & {
  fragment: AttributeDeleteButtonFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function AttributeDeleteButton({
  fragment,
  dispatch,
  ...props
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [deleteAttriute, { loading }] = useDeleteAttributeMutation({
    update(cache) {
      const id = cache.identify(fragment);
      cache.evict({ id });
      cache.gc();
    },
  });

  const onDelete = async () => {
    await deleteAttriute({
      variables: {
        attributeId: fragment.id,
      },
    });

    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: PaneIdentifier.Default,
      },
    });
  };

  return (
    <AccessButton
      variant="destructive"
      onClick={onDelete}
      loading={loading}
      type="button"
      {...props}
    >
      {t("AttributeDeleteButton.label")}
    </AccessButton>
  );
}
