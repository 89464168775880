import { Dispatch } from "react";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useTranslation } from "react-i18next";
import {
  EditorAction,
  PaneIdentifier,
  TabIdentifier,
} from "../../editor/reducers";
import AttributesPane from "../AttributesPane";
import AttributesPaneControls from "../AttributesPaneControls";
import {
  FigureCreationPaneFragment,
  useCreateFigureAttributeMutation,
} from "../../client/generated";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: FigureCreationPaneFragment;
};

export default function FigureCreationPane({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [createFigureAttribute] = useCreateFigureAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await createFigureAttribute({ variables: { regionId: fragment.id } });

    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: PaneIdentifier.Default,
      },
    });
  };

  return (
    <AttributesPane value={PaneIdentifier.FigureCreation}>
      <AttributesPaneControls
        title={t("FigureCreationPane.title")}
        dispatch={dispatch}
      />
      <form onSubmit={onSubmit} className="p-4 flex flex-col">
        <AccessButton type="submit">
          {t("FigureCreationPane.labels.add")}
        </AccessButton>
      </form>
    </AttributesPane>
  );
}
