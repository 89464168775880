import { Dispatch, useState } from "react";
import AttributesPane from "../AttributesPane";
import AttributesPaneControls from "../AttributesPaneControls";
import {
  EditorAction,
  PaneIdentifier,
  TabIdentifier,
} from "../../editor/reducers";
import {
  TableCellUpdatePaneFragment,
  useUpdateTableCellMutation,
} from "../../client/generated";
import AttributeDeleteButton from "../AttributeDeleteButton";
import { useTranslation } from "react-i18next";
import TabelCellRowInput from "./TabelCellRowInput";
import TableCellColumnInput from "./TableCellColumnInput";
import { AccessButton } from "@acdc2/ui/components/access-button";

type Props = {
  fragment: TableCellUpdatePaneFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function TableCellUpdatePane({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [rowSpan, setRowSpan] = useState<number>(fragment.rowSpan);
  const [columnSpan, setColumnSpan] = useState<number>(fragment.columnSpan);

  const [updateTableCell, { loading }] = useUpdateTableCellMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await updateTableCell({
      variables: {
        attributeId: fragment.id,
        rowSpan,
        columnSpan,
      },
    });

    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: PaneIdentifier.Default,
      },
    });
  };

  return (
    <AttributesPane value={fragment.id}>
      <AttributesPaneControls
        title={t("TableCellUpdatePane.title")}
        dispatch={dispatch}
      />
      <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
        <TabelCellRowInput
          value={rowSpan}
          onChange={(event) => setRowSpan(parseInt(event.target.value))}
        />
        <TableCellColumnInput
          value={columnSpan}
          onChange={(event) => setColumnSpan(parseInt(event.target.value))}
        />
        <AccessButton type="submit" loading={loading}>
          {t("TableCellUpdatePane.labels.confirm")}
        </AccessButton>
        <AttributeDeleteButton fragment={fragment} dispatch={dispatch} />
      </form>
    </AttributesPane>
  );
}
