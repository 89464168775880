import * as Tabs from "@radix-ui/react-tabs";
import { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { skipToken, useSuspenseQuery } from "@apollo/client";
import { EditorAction, EditorState, TabIdentifier } from "./reducers";
import NavbarTabContent from "../shell/NavbarTabContent";
import SidebarMissingSelection from "../shell/SidebarMissingSelection";
import {
  EditorAttributesNavbarTabContentDocument,
  EditorAttributesNavbarTabContentQuery,
  EditorAttributesNavbarTabContentQueryVariables,
} from "../client/generated";
import AttributesListPane from "../attributes/AttributesListPane";
import HeadingLevelCreationPane from "../attributes/heading-level/HeadingLevelCreationPane";
import HeadingLevelUpdatePane from "../attributes/heading-level/HeadingLevelUpdatePane";
import TextCreationPane from "../attributes/text/TextCreationPane";
import TextUpdatePane from "../attributes/text/TextUpdatePane";
import AlternativeTextCreationPane from "../attributes/alternative-text/AlternativeTextCreationPane";
import CaptionCreationPane from "../attributes/caption/CaptionCreationPane";
import ExcludeReadingOrderCreationPane from "../attributes/exclude-reading-order/ExcludeReadingOrderCreationPane";
import ExpressionCreationPane from "../attributes/expression/ExpressionCreationPane";
import FigureCreationPane from "../attributes/figure/FigureCreationPane";
import ImageCreationPane from "../attributes/image/ImageCreationPane";
import ListCreationPane from "../attributes/list/ListCreationPane";
import ListItemCreationPane from "../attributes/list-item/ListItemCreationPane";
import PaginationArtifactCreationPane from "../attributes/pagination-artifact/PaginationArtifactCreationPane";
import TableCreationPane from "../attributes/table/TableCreationPane";
import TableCellCreationPane from "../attributes/table-cell/TableCellCreationPane";
import TableHeaderCellCreationPane from "../attributes/table-header-cell/TableHeaderCellCreationPane";
import TableOfContentsCreationPane from "../attributes/table-of-contents/TableOfContentsCreationPane";
import TableOfContentsItemCreationPane from "../attributes/table-of-contents-item/TableOfContentsItemCreationPane";
import TableHeaderCellUpdatePane from "../attributes/table-header-cell/TableHeaderCellUpdatePane";
import TableCellUpdatePane from "../attributes/table-cell/TableCellUpdatePane";
import TableUpdatePane from "../attributes/table/TableUpdatePane";
import ListItemUpdatePane from "../attributes/list-item/ListItemUpdatePane";
import ListUpdatePane from "../attributes/list/ListUpdatePane";
import ExpressionUpdatePane from "../attributes/expression/ExpressionUpdatePane";
import CaptionUpdatePane from "../attributes/caption/CaptionUpdatePane";
import AlternativeTextUpdatePane from "../attributes/alternative-text/AlternativeTextUpdatePane";
import PaginationArtifactUpdatePane from "../attributes/pagination-artifact/PaginationArtifactUpdatePane";
import ExcludeReadingOrderUpdatePane from "../attributes/exclude-reading-order/ExcludeReadingOrderUpdatePane";
import FigureUpdatePane from "../attributes/figure/FigureUpdatePane";
import ImageUpdatePane from "../attributes/image/ImageUpdatePane";
import TableOfContentsUpdatePane from "../attributes/table-of-contents/TableOfContentsUpdatePane";
import TableOfContentsItemUpdatePane from "../attributes/table-of-contents-item/TableOfContentsItemUpdatePane";
import ListItemLabelCreationPane from "../attributes/list-item-label/ListItemLabelCreationPane";
import ListItemLabelUpdatePane from "../attributes/list-item-label/ListItemLabelUpdatePane";
import TableOfContentsHeadingCreationPane from "../attributes/table-of-contents-heading/TableOfContentsHeadingCreationPane";
import TableOfContentsHeadingUpdatePane from "../attributes/table-of-contents-heading/TableOfContentsHeadingUpdatePane";
import TableOfContentsPageNumberCreationPane from "../attributes/table-of-contents-page-number/TableOfContentsPageNumberCreationPane";
import TableOfContentsPageNumberUpdatePane from "../attributes/table-of-contents-page-number/TableOfContentsPageNumberUpdatePane";

type Props = {
  state: EditorState;
  dispatch: Dispatch<EditorAction>;
};

export default function EditorAttributesNavbarTabContent({
  state,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <NavbarTabContent
      value={TabIdentifier.Attributes}
      title={t("EditorAttributesNavbarTabContent.title")}
    >
      <AttributesEditorNavbarTabContentBody state={state} dispatch={dispatch} />
    </NavbarTabContent>
  );
}

type BodyProps = {
  state: EditorState;
  dispatch: Dispatch<EditorAction>;
};

function AttributesEditorNavbarTabContentBody({
  state,
  dispatch,
}: BodyProps): JSX.Element {
  const { data } = useSuspenseQuery<
    EditorAttributesNavbarTabContentQuery,
    EditorAttributesNavbarTabContentQueryVariables
  >(
    EditorAttributesNavbarTabContentDocument,
    state.selectedRegionId
      ? { variables: { selectedRegionId: state.selectedRegionId } }
      : skipToken,
  );

  if (data?.selectedRegion?.__typename !== "Region") {
    return <SidebarMissingSelection />;
  }

  return (
    <Tabs.Root value={state.paneState.paneIdentifier} className="flex-1">
      <AttributesListPane dispatch={dispatch} fragment={data.selectedRegion} />

      <AlternativeTextCreationPane
        dispatch={dispatch}
        fragment={data.selectedRegion}
      />
      <CaptionCreationPane dispatch={dispatch} fragment={data.selectedRegion} />
      <ExcludeReadingOrderCreationPane
        dispatch={dispatch}
        fragment={data.selectedRegion}
      />
      <ExpressionCreationPane
        dispatch={dispatch}
        fragment={data.selectedRegion}
      />
      <FigureCreationPane dispatch={dispatch} fragment={data.selectedRegion} />
      <HeadingLevelCreationPane
        dispatch={dispatch}
        fragment={data.selectedRegion}
      />
      <ImageCreationPane dispatch={dispatch} fragment={data.selectedRegion} />
      <ListCreationPane dispatch={dispatch} fragment={data.selectedRegion} />
      <ListItemCreationPane
        dispatch={dispatch}
        fragment={data.selectedRegion}
      />
      <ListItemLabelCreationPane
        dispatch={dispatch}
        fragment={data.selectedRegion}
      />
      <PaginationArtifactCreationPane
        dispatch={dispatch}
        fragment={data.selectedRegion}
      />
      <TableCreationPane dispatch={dispatch} />
      <TableCellCreationPane
        dispatch={dispatch}
        fragment={data.selectedRegion}
      />
      <TableHeaderCellCreationPane dispatch={dispatch} />
      <TableOfContentsCreationPane
        dispatch={dispatch}
        fragment={data.selectedRegion}
      />
      <TableOfContentsHeadingCreationPane
        dispatch={dispatch}
        fragment={data.selectedRegion}
      />
      <TableOfContentsItemCreationPane
        dispatch={dispatch}
        fragment={data.selectedRegion}
      />
      <TableOfContentsPageNumberCreationPane
        dispatch={dispatch}
        fragment={data.selectedRegion}
      />
      <TextCreationPane dispatch={dispatch} fragment={data.selectedRegion} />

      {data.selectedRegion.attributes.map((attribute) =>
        attribute.__typename === "AlternativeTextAttribute" ? (
          <AlternativeTextUpdatePane fragment={attribute} dispatch={dispatch} />
        ) : attribute.__typename === "CaptionAttribute" ? (
          <CaptionUpdatePane fragment={attribute} dispatch={dispatch} />
        ) : attribute.__typename === "ExcludeReadingOrderAttribute" ? (
          <ExcludeReadingOrderUpdatePane
            fragment={attribute}
            dispatch={dispatch}
          />
        ) : attribute.__typename === "ExpressionAttribute" ? (
          <ExpressionUpdatePane fragment={attribute} dispatch={dispatch} />
        ) : attribute.__typename === "FigureAttribute" ? (
          <FigureUpdatePane fragment={attribute} dispatch={dispatch} />
        ) : attribute.__typename === "HeaderLevelAttribute" ? (
          <HeadingLevelUpdatePane fragment={attribute} dispatch={dispatch} />
        ) : attribute.__typename === "ImageAttribute" ? (
          <ImageUpdatePane fragment={attribute} dispatch={dispatch} />
        ) : attribute.__typename === "ListAttribute" ? (
          <ListUpdatePane fragment={attribute} dispatch={dispatch} />
        ) : attribute.__typename === "ListItemAttribute" ? (
          <ListItemUpdatePane fragment={attribute} dispatch={dispatch} />
        ) : attribute.__typename === "ListItemLabelAttribute" ? (
          <ListItemLabelUpdatePane fragment={attribute} dispatch={dispatch} />
        ) : attribute.__typename === "PaginationArtifactAttribute" ? (
          <PaginationArtifactUpdatePane
            fragment={attribute}
            dispatch={dispatch}
          />
        ) : attribute.__typename === "TableAttribute" ? (
          <TableUpdatePane fragment={attribute} dispatch={dispatch} />
        ) : attribute.__typename === "TableCellAttribute" ? (
          <TableCellUpdatePane fragment={attribute} dispatch={dispatch} />
        ) : attribute.__typename === "TableHeaderCellAttribute" ? (
          <TableHeaderCellUpdatePane fragment={attribute} dispatch={dispatch} />
        ) : attribute.__typename === "TableOfContentsAttribute" ? (
          <TableOfContentsUpdatePane fragment={attribute} dispatch={dispatch} />
        ) : attribute.__typename === "TableOfContentsHeadingAttribute" ? (
          <TableOfContentsHeadingUpdatePane
            fragment={attribute}
            dispatch={dispatch}
          />
        ) : attribute.__typename === "TableOfContentsItemAttribute" ? (
          <TableOfContentsItemUpdatePane
            fragment={attribute}
            dispatch={dispatch}
          />
        ) : attribute.__typename === "TableOfContentsPageNumberAttribute" ? (
          <TableOfContentsPageNumberUpdatePane
            fragment={attribute}
            dispatch={dispatch}
          />
        ) : attribute.__typename === "TextAttribute" ? (
          <TextUpdatePane fragment={attribute} dispatch={dispatch} />
        ) : null,
      )}
    </Tabs.Root>
  );
}
