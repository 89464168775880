import { Dispatch } from "react";
import { TabIdentifier, EditorAction } from "../editor/reducers";
import { AttributesListItemFragment } from "../client/generated";
import AttributeDeleteButton from "./AttributeDeleteButton";

type Props = {
  fragment: AttributesListItemFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function AttributesListItem({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const onEdit = () => {
    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: fragment.id,
      },
    });
  };

  return (
    <li>
      <div>{fragment.__typename}</div>
      {fragment.__typename === "HeaderLevelAttribute" && (
        <button onClick={onEdit}>Edit</button>
      )}
      {fragment.__typename === "TextAttribute" && (
        <button onClick={onEdit}>Edit</button>
      )}
      {fragment.__typename === "AlternativeTextAttribute" && (
        <button onClick={onEdit}>Edit</button>
      )}
      {fragment.__typename === "PaginationArtifactAttribute" && (
        <button onClick={onEdit}>Edit</button>
      )}
      {fragment.__typename === "ListAttribute" && (
        <button onClick={onEdit}>Edit</button>
      )}
      {fragment.__typename === "ListItemAttribute" && (
        <button onClick={onEdit}>Edit</button>
      )}
      {fragment.__typename === "CaptionAttribute" && (
        <button onClick={onEdit}>Edit</button>
      )}
      {fragment.__typename === "ExpressionAttribute" && (
        <button onClick={onEdit}>Edit</button>
      )}
      <span> | </span>
      <AttributeDeleteButton fragment={fragment} dispatch={dispatch} />
    </li>
  );
}
