import { Dispatch } from "react";
import AttributesPane from "../AttributesPane";
import AttributesPaneControls from "../AttributesPaneControls";
import { EditorAction } from "../../editor/reducers";
import { TableUpdatePaneFragment } from "../../client/generated";
import AttributeDeleteButton from "../AttributeDeleteButton";
import { useTranslation } from "react-i18next";

type Props = {
  fragment: TableUpdatePaneFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function TableUpdatePane({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <AttributesPane value={fragment.id}>
      <AttributesPaneControls
        title={t("TableUpdatePane.title")}
        dispatch={dispatch}
      />
      <div className="flex flex-col p-4">
        <AttributeDeleteButton fragment={fragment} dispatch={dispatch} />
      </div>
    </AttributesPane>
  );
}
