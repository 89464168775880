import { Dispatch } from "react";
import { EditorAction, PaneIdentifier } from "../../editor/reducers";
import AttributesPane from "../AttributesPane";
import AttributesPaneControls from "../AttributesPaneControls";
import { useTranslation } from "react-i18next";

type Props = {
  dispatch: Dispatch<EditorAction>;
};

export default function TableHeaderCellCreationPane({
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <AttributesPane value={PaneIdentifier.TableHeaderCellCreation}>
      <AttributesPaneControls
        title={t("TableHeaderCellCreationPane.title")}
        dispatch={dispatch}
      />
      Table Header Cell Creation Pane
    </AttributesPane>
  );
}
