import { AccessButton } from "@acdc2/ui/components/access-button";
import { useTranslation } from "react-i18next";
import { Dispatch, useState } from "react";
import {
  TabIdentifier,
  EditorAction,
  PaneIdentifier,
} from "../../editor/reducers";
import AttributesPaneControls from "../AttributesPaneControls";
import AttributesPane from "../AttributesPane";
import {
  HeadingLevelCreationPaneFragment,
  useCreateHeadingLevelAttributeMutation,
} from "../../client/generated";
import HeadingLevelSelect from "./HeadingLevelSelect";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: HeadingLevelCreationPaneFragment;
};

export default function HeadingLevelCreationPane({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [level, setLevel] = useState<string>();

  const [createHeadingLevelAttribute] =
    useCreateHeadingLevelAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!level) return;

    await createHeadingLevelAttribute({
      variables: {
        regionId: fragment.id,
        level: parseInt(level),
      },
    });

    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: PaneIdentifier.Default,
      },
    });
  };

  return (
    <AttributesPane value={PaneIdentifier.HeadingLevelCreation}>
      <AttributesPaneControls
        title={t("HeadingLevelCreationPane.title")}
        dispatch={dispatch}
      />
      <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
        <HeadingLevelSelect value={level} onValueChange={setLevel} required />
        <AccessButton type="submit">
          {t("HeadingLevelCreationPane.buttonLabels.add")}
        </AccessButton>
      </form>
    </AttributesPane>
  );
}
