import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@acdc2/ui/components/dropdown-menu";
import {
  DocumentSharePermissionFragment,
  Permission,
  useUpdateDocumentPermissionMutation,
} from "../client/generated";
import { Icon } from "@mdi/react";
import { mdiChevronDown } from "@mdi/js";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { AccessLoading } from "@acdc2/ui/components/access-loading";

type Props = {
  fragment: DocumentSharePermissionFragment;
};

export default function DocumentSharePermission({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const [updateDocumentPermission, { loading }] =
    useUpdateDocumentPermissionMutation({
      update(cache, { data }) {
        if (
          data?.updateDocumentPermission.membership?.permission ===
          Permission.None
        ) {
          const id = cache.identify(fragment);
          cache.evict({ id });
          cache.gc();
        }
      },
    });

  const onSelect = async (event: Event, permission: Permission) => {
    event.preventDefault();
    try {
      const result = await updateDocumentPermission({
        variables: {
          documentId: fragment.document.id,
          userId: fragment.user.id,
          permission,
        },
      });

      if (!result.data?.updateDocumentPermission.userErrors) {
        setOpen(false);
      }
    } catch (error) {
      console.error("Failed to updating document permission", error);
    }
  };

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger>
        <AccessButton variant="ghost">
          <span>
            {t(`DocumentSharePermission.permissions.${fragment.permission}`)}
          </span>
          <Icon size={1} path={mdiChevronDown} />
        </AccessButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel className="flex justify-between items-center">
          <span>{fragment.user.username}</span>
          {loading && <AccessLoading className="h-4" />}
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuRadioGroup value={fragment.permission}>
          <DropdownMenuRadioItem
            value={Permission.Admin}
            onSelect={(event) => onSelect(event, Permission.Admin)}
          >
            {t("DocumentSharePermission.permissions.ADMIN")}
          </DropdownMenuRadioItem>
          <DropdownMenuRadioItem
            value={Permission.Write}
            onSelect={(event) => onSelect(event, Permission.Write)}
          >
            {t("DocumentSharePermission.permissions.WRITE")}
          </DropdownMenuRadioItem>
          <DropdownMenuRadioItem
            value={Permission.Read}
            onSelect={(event) => onSelect(event, Permission.Read)}
          >
            {t("DocumentSharePermission.permissions.READ")}
          </DropdownMenuRadioItem>
        </DropdownMenuRadioGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onSelect={(event) => onSelect(event, Permission.None)}
        >
          {t("DocumentSharePermission.remove")}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
