import { Dispatch, useState } from "react";
import { Button } from "@acdc2/ui/components/button";
import { useTranslation } from "react-i18next";
import { Textarea } from "@acdc2/ui/components/textarea";
import {
  EditorAction,
  PaneIdentifier,
  TabIdentifier,
} from "../../editor/reducers";
import AttributesPane from "../AttributesPane";
import AttributesPaneControls from "../AttributesPaneControls";
import {
  AlternativeTextCreationPaneFragment,
  useCreateAlternativeTextAttributeMutation,
} from "../../client/generated";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: AlternativeTextCreationPaneFragment;
};

export default function AlternativeTextCreationPane({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [alternativeText, setAlternativeText] = useState<string>();

  const [createAlternativeText] = useCreateAlternativeTextAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!alternativeText) return;

    await createAlternativeText({
      variables: { alternativeText, regionId: fragment.id },
    });

    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: PaneIdentifier.Default,
      },
    });
  };

  return (
    <AttributesPane value={PaneIdentifier.AlternativeTextCreation}>
      <AttributesPaneControls
        title={t("AlternativeTextCreationPane.title")}
        dispatch={dispatch}
      />
      <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
        <Textarea
          placeholder={t("AlternativeTextCreationPane.placeholder")}
          required
          value={alternativeText}
          onChange={(event) => setAlternativeText(event.target.value)}
          name="alternativeText"
        />
        <Button type="submit">
          {t("AlternativeTextCreationPane.labels.confirm")}
        </Button>
      </form>
    </AttributesPane>
  );
}
