import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@acdc2/ui/components/avatar";
import { ReactNode } from "react";

type Props = {
  username: string;
  avatarUrl?: string;
  right?: ReactNode;
};

export default function DocumentShareCollaborator({
  username,
  avatarUrl,
  right,
}: Props): JSX.Element {
  return (
    <div className="flex gap-4 items-center">
      <Avatar>
        <AvatarImage src={avatarUrl} />
        <AvatarFallback>{username[0].toUpperCase()}</AvatarFallback>
      </Avatar>
      <span>{username}</span>
      <div className="ml-auto">{right}</div>
    </div>
  );
}
