import { useTranslation } from "react-i18next";
import AttributePreview from "../AttributePreview";
import { PaginationArtifactPreviewFragment } from "../../client/generated";
import { Dispatch } from "react";
import { EditorAction } from "../../editor/reducers";

type Props = {
  fragment: PaginationArtifactPreviewFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function PaginationArtifactPreview({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <AttributePreview
      fragment={fragment}
      label={t("PaginationArtifactPreview.label")}
      preview={t(`PaginationArtifactPreview.preview.${fragment.type}`)}
      dispatch={dispatch}
    />
  );
}
