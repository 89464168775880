import { useTranslation } from "react-i18next";
import { TabIdentifier } from "./reducers";
import NavbarTabContent from "../shell/NavbarTabContent";

export default function EditorCheckerNavbarTabContent(): JSX.Element {
  const { t } = useTranslation();

  return (
    <NavbarTabContent
      value={TabIdentifier.Checker}
      title={t("EditorCheckerNavbarTabContent.title")}
    >
      Accessibility Checker Content
    </NavbarTabContent>
  );
}
