import { Dispatch } from "react";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useTranslation } from "react-i18next";
import {
  EditorAction,
  PaneIdentifier,
  TabIdentifier,
} from "../../editor/reducers";
import AttributesPane from "../AttributesPane";
import AttributesPaneControls from "../AttributesPaneControls";
import {
  ExcludeReadingOrderCreationPaneFragment,
  useCreateExcludeReadingOrderAttributeMutation,
} from "../../client/generated";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: ExcludeReadingOrderCreationPaneFragment;
};

export default function ExcludeReadingOrderCreationPane({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [createExcludeReadingOrderAttribute] =
    useCreateExcludeReadingOrderAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await createExcludeReadingOrderAttribute({
      variables: { regionId: fragment.id },
    });

    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: PaneIdentifier.Default,
      },
    });
  };

  return (
    <AttributesPane value={PaneIdentifier.ExcludeReadingOrderCreation}>
      <AttributesPaneControls
        title={t("ExcludeReadingOrderCreationPane.title")}
        dispatch={dispatch}
      />
      <form className="p-4 flex flex-col" onSubmit={onSubmit}>
        <AccessButton type="submit">
          {t("ExcludeReadingOrderCreationPane.labels.add")}
        </AccessButton>
      </form>
    </AttributesPane>
  );
}
