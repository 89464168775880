import { useTranslation } from "react-i18next";
import { TabIdentifier } from "./reducers";
import NavbarTabContent from "../shell/NavbarTabContent";
import {
  EditorReadingOrderNavbarTabContentFragment,
  SortableRegionPreviewFragment,
} from "../client/generated";
import { useState } from "react";
import {
  closestCorners,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import RegionDropzone from "../regions/RegionDropzone";
import SortableRegionPreview from "../regions/SortableRegionPreview";
import SidebarDivider from "../shell/SidebarDivider";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { ScrollArea } from "@acdc2/ui/components/scroll-area";
import { Icon } from "@mdi/react";
import { mdiAutoFix } from "@mdi/js";

type Props = {
  fragment: EditorReadingOrderNavbarTabContentFragment;
};

type Items = {
  todo: EditorReadingOrderNavbarTabContentFragment["regions"];
  done: EditorReadingOrderNavbarTabContentFragment["regions"];
};

export default function EditorReadingOrderNavbarTabContent({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [zonedItems, setZonedItems] = useState<Items>({
    todo: fragment.regions.filter((region) => region.readingOrderIndex === 0),
    done: fragment.regions.filter((region) => region.readingOrderIndex !== 0),
  });

  const [activeRegion, setActiveRegion] =
    useState<SortableRegionPreviewFragment>();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const getZoneKey = (id: UniqueIdentifier) => {
    if (id === "todo" || id === "done") {
      return id;
    }

    if (zonedItems.todo.some((region) => region.id === id)) {
      return "todo";
    } else {
      return "done";
    }
  };

  const handleDragStart = (event: DragStartEvent) => {
    setActiveRegion(event.active.data.current as SortableRegionPreviewFragment);
  };

  const handleDragOver = (event: DragOverEvent) => {
    if (!event.over?.id) return;
    const sourceZoneKey = getZoneKey(event.active.id);
    const targetZoneKey = getZoneKey(event.over.id);
    if (sourceZoneKey === targetZoneKey) return;

    setZonedItems((oldZonedItems) => {
      if (!event.over?.id) return oldZonedItems;

      const sourceItems = oldZonedItems[sourceZoneKey];
      const targetItems = oldZonedItems[targetZoneKey];
      const sourceItemIndex = sourceItems.findIndex(
        (region) => region.id === event.active.id,
      );

      if (event.over.id in oldZonedItems) {
        return {
          ...oldZonedItems,
          [sourceZoneKey]: [
            ...oldZonedItems[sourceZoneKey].filter(
              (region) => region.id !== event.active.id,
            ),
          ],
          [targetZoneKey]: [
            ...oldZonedItems[targetZoneKey],
            oldZonedItems[sourceZoneKey][sourceItemIndex],
          ],
        };
      }

      const targetItemIndex = targetItems.findIndex(
        (region) => region.id === event.over?.id,
      );
      const isBelowLastItem =
        targetItemIndex === targetItems.length - 1 &&
        event.active.rect.current.translated &&
        event.active.rect.current.translated.top >
          event.over.rect.top + event.over.rect.height;

      const modifier = isBelowLastItem ? 1 : 0;
      const modifiedTargetItemIndex =
        targetItemIndex >= 0
          ? targetItemIndex + modifier
          : targetItems.length + 1;

      return {
        ...oldZonedItems,
        [sourceZoneKey]: [
          ...oldZonedItems[sourceZoneKey].filter(
            (region) => region.id !== event.active.id,
          ),
        ],
        [targetZoneKey]: [
          ...oldZonedItems[targetZoneKey].slice(0, modifiedTargetItemIndex),
          oldZonedItems[sourceZoneKey][sourceItemIndex],
          ...oldZonedItems[targetZoneKey].slice(
            modifiedTargetItemIndex,
            oldZonedItems[targetZoneKey].length,
          ),
        ],
      };
    });
  };

  const handleDragEnd = (event: DragEndEvent) => {
    if (!event.over?.id) return;
    const sourceZoneKey = getZoneKey(event.active.id);
    const targetZoneKey = getZoneKey(event.over.id);
    if (sourceZoneKey !== targetZoneKey) return;

    setZonedItems((oldZonedItems) => {
      if (!event.over?.id) return oldZonedItems;

      const sourceItemIndex = oldZonedItems[sourceZoneKey].findIndex(
        (region) => region.id === event.active.id,
      );
      const targetItemIndex = oldZonedItems[sourceZoneKey].findIndex(
        (region) => region.id === event.over?.id,
      );

      return {
        ...oldZonedItems,
        [sourceZoneKey]: arrayMove(
          oldZonedItems[sourceZoneKey],
          sourceItemIndex,
          targetItemIndex,
        ),
      };
    });

    setActiveRegion(undefined);
  };

  const predict = () => {
    const sortedRegions = [...fragment.regions].sort((a, b) => {
      const aTop = a.shape.aabb.top;
      const bTop = b.shape.aabb.top;
      if (aTop !== bTop) return aTop - bTop;

      const aLeft = a.shape.aabb.left;
      const bLeft = b.shape.aabb.left;
      return aLeft - bLeft;
    });

    setZonedItems({
      todo: [],
      done: sortedRegions,
    });
  };

  return (
    <NavbarTabContent
      value={TabIdentifier.ReadingOrder}
      title={t("EditorReadingOrderNavbarTabContent.title")}
    >
      <div>
        <div className="p-4 flex flex-col">
          <AccessButton onClick={predict} className="gap-2" confetti>
            <Icon path={mdiAutoFix} size={0.75} />
            {t("EditorReadingOrderNavbarTabContent.buttons.predict")}
          </AccessButton>
        </div>
        <SidebarDivider />
      </div>
      <ScrollArea className="flex-1">
        <div className="flex flex-col gap-4 p-4">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
          >
            <RegionDropzone
              title={t("EditorReadingOrderNavbarTabContent.zones.todo")}
              id="todo"
              items={zonedItems.todo}
            />

            <RegionDropzone
              title={t("EditorReadingOrderNavbarTabContent.zones.done")}
              id="done"
              items={zonedItems.done}
            />

            <DragOverlay>
              {activeRegion ? (
                <SortableRegionPreview fragment={activeRegion} />
              ) : null}
            </DragOverlay>
          </DndContext>
        </div>
      </ScrollArea>
    </NavbarTabContent>
  );
}
