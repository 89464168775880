import { Dispatch, useState } from "react";
import {
  EditorAction,
  PaneIdentifier,
  TabIdentifier,
} from "../../editor/reducers";
import AttributesPane from "../AttributesPane";
import AttributesPaneControls from "../AttributesPaneControls";
import { useTranslation } from "react-i18next";
import { AccessButton } from "@acdc2/ui/components/access-button";
import TabelCellRowInput from "./TabelCellRowInput";
import TableCellColumnInput from "./TableCellColumnInput";
import {
  TableCellCreationPaneFragment,
  useCreateTableCellMutation,
} from "../../client/generated";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: TableCellCreationPaneFragment;
};

export default function TableCellCreationPane({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [rowSpan, setRowSpan] = useState<number>();
  const [columnSpan, setColumnSpan] = useState<number>();

  const [createTableCell] = useCreateTableCellMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!rowSpan || !columnSpan) return;

    await createTableCell({
      variables: {
        regionId: fragment.id,
        rowSpan,
        columnSpan,
      },
    });

    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: PaneIdentifier.Default,
      },
    });
  };

  return (
    <AttributesPane value={PaneIdentifier.TableCellCreation}>
      <AttributesPaneControls
        title={t("TableCellCreationPane.title")}
        dispatch={dispatch}
      />
      <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
        <TabelCellRowInput
          value={rowSpan ?? ""}
          onChange={(event) => setRowSpan(parseInt(event.target.value))}
        />
        <TableCellColumnInput
          value={columnSpan ?? ""}
          onChange={(event) => setColumnSpan(parseInt(event.target.value))}
        />
        <AccessButton type="submit">
          {t("TableCellCreationPane.labels.add")}
        </AccessButton>
      </form>
    </AttributesPane>
  );
}
