import { mdiTagMultipleOutline } from "@mdi/js";
import { useTranslation } from "react-i18next";
import NavbarTabButton from "../shell/NavbarTabButton";
import { TabIdentifier } from "./reducers";

type Props = {
  disabled?: boolean;
};

export default function EditorAttributesNavbarTabButton({
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <NavbarTabButton
      tooltip={t("EditorAttributesNavbarTabButton.tooltip")}
      value={TabIdentifier.Attributes}
      iconPath={mdiTagMultipleOutline}
      disabled={disabled}
    />
  );
}
