import { Dispatch, useId, useState } from "react";
import AttributesPane from "../AttributesPane";
import AttributesPaneControls from "../AttributesPaneControls";
import {
  EditorAction,
  PaneIdentifier,
  TabIdentifier,
} from "../../editor/reducers";
import {
  ExpressionUpdatePaneFragment,
  useUpdateExpressionAttributeMutation,
} from "../../client/generated";
import { Textarea } from "@acdc2/ui/components/textarea";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useTranslation } from "react-i18next";
import { Label } from "@acdc2/ui/components/label";
import KaTeXPreviewScrollArea from "./KaTeXPreviewScrollArea";
import AttributeDeleteButton from "../AttributeDeleteButton";

type Props = {
  fragment: ExpressionUpdatePaneFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function ExpressionUpdatePane({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [latexExpression, setLatexExpression] = useState<string>(
    fragment.expressionLatex,
  );

  const previewAreaId = useId();
  const textAreaId = useId();

  const [updateExpressionAttribute, { loading }] =
    useUpdateExpressionAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await updateExpressionAttribute({
      variables: {
        attributeId: fragment.id,
        expressionLatex: latexExpression,
      },
    });

    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: PaneIdentifier.Default,
      },
    });
  };

  return (
    <AttributesPane value={fragment.id}>
      <AttributesPaneControls
        title={t("ExpressionUpdatePane.title")}
        dispatch={dispatch}
      />
      <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
        <div className="flex flex-col gap-2">
          <Label htmlFor={previewAreaId}>
            <span>{t("ExpressionUpdatePane.labels.preview")}</span>
          </Label>
          <KaTeXPreviewScrollArea
            latexExpression={latexExpression}
            id={previewAreaId}
          />
        </div>

        <div className="flex flex-col gap-2">
          <Label htmlFor={textAreaId}>
            <span>{t("ExpressionUpdatePane.labels.textarea")}</span>
          </Label>
          <Textarea
            id={textAreaId}
            required
            value={latexExpression}
            onChange={(event) => setLatexExpression(event.target.value)}
            rows={8}
          />
        </div>

        <div className="flex flex-col gap-2">
          <AccessButton type="submit" loading={loading}>
            {t("ExpressionUpdatePane.labels.confirm")}
          </AccessButton>
          <AttributeDeleteButton fragment={fragment} dispatch={dispatch} />
        </div>
      </form>
    </AttributesPane>
  );
}
