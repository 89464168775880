import { Dispatch, useState } from "react";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useTranslation } from "react-i18next";
import {
  EditorAction,
  PaneIdentifier,
  TabIdentifier,
} from "../../editor/reducers";
import AttributesPaneControls from "../AttributesPaneControls";
import AttributesPane from "../AttributesPane";
import {
  PaginationArtifactType,
  PaginationArtifactUpdatePaneFragment,
  useUpdatePaginationArtifactAttributeMutation,
} from "../../client/generated";
import PaginationArtifactTypeSelect from "./PaginationArtifactTypeSelect";
import AttributeDeleteButton from "../AttributeDeleteButton";

type Props = {
  fragment: PaginationArtifactUpdatePaneFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function PaginationArtifactUpdatePane({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [artifactType, setArtifactType] = useState(fragment.type);

  const [updatePaginationArtifact, { loading }] =
    useUpdatePaginationArtifactAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await updatePaginationArtifact({
      variables: {
        attributeId: fragment.id,
        paginationArtifactType: artifactType as PaginationArtifactType,
      },
    });

    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: PaneIdentifier.Default,
      },
    });
  };

  return (
    <AttributesPane value={fragment.id}>
      <AttributesPaneControls
        title={t("PaginationArtifactUpdatePane.title")}
        dispatch={dispatch}
      />
      <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
        <PaginationArtifactTypeSelect
          value={artifactType}
          onValueChange={(newArtifactType) =>
            setArtifactType(newArtifactType as PaginationArtifactType)
          }
        />
        <div className="flex flex-col gap-2">
          <AccessButton type="submit" loading={loading}>
            {t("PaginationArtifactUpdatePane.labels.confirm")}
          </AccessButton>
          <AttributeDeleteButton fragment={fragment} dispatch={dispatch} />
        </div>
      </form>
    </AttributesPane>
  );
}
