import { Dispatch, useState } from "react";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useTranslation } from "react-i18next";
import {
  EditorAction,
  PaneIdentifier,
  TabIdentifier,
} from "../../editor/reducers";
import AttributesPane from "../AttributesPane";
import AttributesPaneControls from "../AttributesPaneControls";
import {
  ListCreationPaneFragment,
  ListType,
  useCreateListAttributeMutation,
} from "../../client/generated";
import ListTypeSelect from "./ListTypeSelect";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: ListCreationPaneFragment;
};

export default function ListCreationPane({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [listType, setListType] = useState<string>();
  const [createListAttribute] = useCreateListAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!listType) return;

    await createListAttribute({
      variables: {
        regionId: fragment.id,
        listType: listType as ListType,
      },
    });

    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: PaneIdentifier.Default,
      },
    });
  };

  return (
    <AttributesPane value={PaneIdentifier.ListCreation}>
      <AttributesPaneControls
        title={t("ListCreationPane.title")}
        dispatch={dispatch}
      />
      <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
        <ListTypeSelect value={listType} onValueChange={setListType} required />
        <AccessButton type="submit">
          {t("ListCreationPane.labels.confirm")}
        </AccessButton>
      </form>
    </AttributesPane>
  );
}
