import { useTranslation } from "react-i18next";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { Dispatch, useState } from "react";
import AttributesPaneControls from "../AttributesPaneControls";
import AttributesPane from "../AttributesPane";
import {
  EditorAction,
  PaneIdentifier,
  TabIdentifier,
} from "../../editor/reducers";
import {
  HeadingLevelUpdatePaneFragment,
  useUpdateHeadingLevelAttributeMutation,
} from "../../client/generated";
import HeadingLevelSelect from "./HeadingLevelSelect";
import AttributeDeleteButton from "../AttributeDeleteButton";

type Props = {
  fragment: HeadingLevelUpdatePaneFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function HeadingLevelUpdatePane({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [level, setLevel] = useState(fragment.level.toString());

  const [updateHeadingLevel, { loading }] =
    useUpdateHeadingLevelAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await updateHeadingLevel({
      variables: {
        attributeId: fragment.id,
        level: parseInt(level),
      },
    });

    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: PaneIdentifier.Default,
      },
    });
  };

  return (
    <AttributesPane value={fragment.id}>
      <AttributesPaneControls
        title={t("HeadingLevelUpdatePane.title")}
        dispatch={dispatch}
      />
      <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
        <HeadingLevelSelect
          name="level"
          value={level}
          onValueChange={setLevel}
        />
        <div className="flex flex-col gap-2">
          <AccessButton type="submit" loading={loading}>
            {t("HeadingLevelUpdatePane.labels.confirm")}
          </AccessButton>
          <AttributeDeleteButton fragment={fragment} dispatch={dispatch} />
        </div>
      </form>
    </AttributesPane>
  );
}
