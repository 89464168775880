import { useTranslation } from "react-i18next";
import AttributePreview from "../AttributePreview";
import { ImagePreviewFragment } from "../../client/generated";
import { Dispatch } from "react";
import { EditorAction } from "../../editor/reducers";

type Props = {
  fragment: ImagePreviewFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function ImagePreview({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <AttributePreview
      fragment={fragment}
      label={t("ImagePreview.label")}
      dispatch={dispatch}
    />
  );
}
