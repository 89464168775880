import { mdiTagOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import { EditorAction, TabIdentifier } from "../editor/reducers";
import { Dispatch } from "react";
import { AttributePreviewFragment } from "../client/generated";

type Props = {
  fragment: AttributePreviewFragment;
  label: string;
  preview?: string;
  dispatch: Dispatch<EditorAction>;
};

export default function AttributePreview({
  fragment,
  label,
  preview,
  dispatch,
}: Props): JSX.Element {
  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch({
      type: "selectRegion",
      regionId: fragment.region.id,
    });
    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: fragment.id,
      },
    });
  };

  return (
    <div className="flex">
      <div
        className="flex items-center gap-1 p-2 border-r cursor-pointer hover:text-brand"
        onClick={onClick}
      >
        <Icon path={mdiTagOutline} size="1rem" />
        <span className="font-bold text-xs uppercase">{label}</span>
      </div>
      <div className="p-2 flex-1 truncate text-right text-xs">{preview}</div>
    </div>
  );
}
