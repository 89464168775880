import { useParams } from "react-router";
import { useReducer } from "react";
import { usePageScreenSuspenseQuery } from "../client/generated";
import {
  editorReducer,
  TabIdentifier,
  RegionMode,
  PaneIdentifier,
} from "../editor/reducers";
import Sidebar from "../shell/Sidebar";
import Scaffold from "../shell/Scaffold";
import EditorMetadataNavbarTabButton from "../editor/EditorMetadataNavbarTabButton";
import EditorMetadataNavbarTabContent from "../editor/EditorMetadataNavbarTabContent";
import EditorRegionsNavbarTabButton from "../editor/EditorRegionsNavbarTabButton";
import EditorRegionsNavbarTabContent from "../editor/EditorRegionsNavbarTabContent";
import EditorReadingOrderNavbarTabButton from "../editor/EditorReadingOrderNavbarTabButton";
import EditorReadingOrderNavbarTabContent from "../editor/EditorReadingOrderNavbarTabContent";
import EditorPagesNavbarTabButton from "../editor/EditorPagesNavbarTabButton";
import EditorPagesNavbarTabContent from "../editor/EditorPagesNavbarTabContent";
import EditorAttributesNavbarTabButton from "../editor/EditorAttributesNavbarTabButton";
import EditorAttributesNavbarTabContent from "../editor/EditorAttributesNavbarTabContent";
import EditorCheckerNavbarTabButton from "../editor/EditorCheckerNavbarTabButton";
import EditorCheckerNavbarTabContent from "../editor/EditorCheckerNavbarTabContent";
import OverviewNavbarButton from "../editor/OverviewNavbarButton";
import SidebarDivider from "../shell/SidebarDivider";
import PageEditor from "../editor/PageEditor";
import PreviousPageNavbarButton from "../editor/PreviousPageNavbarButton";
import NextPageNavbarButton from "../editor/NextPageNavbarButton";
import Navbar from "../shell/Navbar";

export default function PageScreen(): JSX.Element {
  const [state, dispatch] = useReducer(editorReducer, {
    mode: RegionMode.Rectangle,
    paneState: {
      tabIdentifier: TabIdentifier.Checker,
      paneIdentifier: PaneIdentifier.Default,
    },
  });
  const { documentId, pageId } = useParams<{
    documentId: string;
    pageId: string;
  }>();

  const { data } = usePageScreenSuspenseQuery({
    variables: { documentId: documentId!, pageId: pageId! },
  });

  if (
    data.document?.__typename !== "Document" ||
    data.page?.__typename !== "Page"
  ) {
    throw TypeError("Document or Page not found");
  }

  return (
    <Scaffold
      tabsValue={state.paneState.tabIdentifier}
      onTabsValueChange={(value) => {
        dispatch({
          type: "switchPane",
          paneState: {
            tabIdentifier: value as TabIdentifier,
            paneIdentifier: PaneIdentifier.Default,
          },
        });
      }}
      navbar={
        <Navbar>
          <OverviewNavbarButton fragment={data.document} />
          <PreviousPageNavbarButton fragment={data.page} />
          <NextPageNavbarButton fragment={data.page} />
          <SidebarDivider />
          <EditorCheckerNavbarTabButton />
          <EditorMetadataNavbarTabButton />
          <EditorPagesNavbarTabButton />
          <EditorRegionsNavbarTabButton />
          <EditorAttributesNavbarTabButton />
          <EditorReadingOrderNavbarTabButton />
        </Navbar>
      }
      sidebar={
        <Sidebar>
          <EditorCheckerNavbarTabContent />
          <EditorMetadataNavbarTabContent fragment={data.document} />
          <EditorPagesNavbarTabContent fragment={data.page} />
          <EditorRegionsNavbarTabContent
            state={state}
            dispatch={dispatch}
            fragment={data.page}
          />
          <EditorAttributesNavbarTabContent state={state} dispatch={dispatch} />
          <EditorReadingOrderNavbarTabContent
            key={data.page.id}
            fragment={data.page}
          />
        </Sidebar>
      }
    >
      <PageEditor fragment={data.page} state={state} dispatch={dispatch} />
    </Scaffold>
  );
}
